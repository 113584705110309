<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                :headers="headers"
                :items="categories"
                :items-per-page="-1"
                :search="searchCat"
                :loading="loadingData"
                item-key="id"
                sort-by="id"
                disable-sort
                no-data-text="No hay datos para mostrar..."
                loading-text="Cargando los datos, por favor espere."
                class="elevation-1"
                :footer-props="{
                      showFirstLastPage: false,
                      disableItemsPerPage: false,
                      itemsPerPageAllText: 'Todas',
                      itemsPerPageText: 'Filas por página:'
                    }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title class="text-subtitle-1 text-md-h5">Categor&iacute;a de los Proyectos</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                id="findtext-cat"
                                label="Buscar.."
                                name="findcat"
                                outlined
                                dense
                                single-line
                                clearable
                                background-color="white"
                                hide-details
                                v-model="searchCat"
                                class="white--text mt-0 pt-0 mr-2"
                                prepend-inner-icon="mdi-magnify"></v-text-field>
                        </v-responsive>
                        <v-btn @click="getCategories"
                               :loading="loadingData"
                               elevation="0"
                               :small="$vuetify.breakpoint.smAndDown"
                               class="mb-2 mr-2"
                        >
                          <v-icon>mdi-reload</v-icon>
                        </v-btn>
                        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown"
                                  v-model="dialog"
                                  max-width="600px"
                                  persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :small="$vuetify.breakpoint.smAndDown"
                                       color="primary caption"
                                       dark
                                       depressed
                                       elevation="0"
                                       class="mb-2"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="newItem">
                                    Adicionar
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation>
                                    <v-card-title class="text-h6 primary">
                                        <span class="white--text headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row dense>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        dense
                                                        v-model="editedItem.name"
                                                        label="Nombre"
                                                        :disabled="loadingSave"
                                                        :rules="textRules"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-textarea
                                                        dense
                                                        v-model="editedItem.description"
                                                        label="Descripción"
                                                        :disabled="loadingSave"
                                                        no-resize
                                                        rows="2"
                                                        counter>
                                                    </v-textarea>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-select
                                                        dense
                                                        v-model="editedItem.classification"
                                                        :items="classifications"
                                                        item-text="description"
                                                        :disabled="loadingSave"
                                                        item-value="name"
                                                        label="Clasificación"
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-select
                                                        dense
                                                        v-model="editedItem.projects"
                                                        :items="projectsList"
                                                        :rules="[v => !!v || 'Un proyecto es obligatorio']"
                                                        item-text="name"
                                                        item-value="id"
                                                        return-object
                                                        :disabled="loadingSave"
                                                        label="Proyectos"
                                                        multiple
                                                        item-color="primary"
                                                        chips
                                                        hint="Seleccione los proyectos asociados"
                                                        persistent-hint>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                            <v-col cols="12">
                                                <v-row no-gutters align="center" justify="center">
                                                    <v-img
                                                        :src="imagePreview != null ? imagePreview : require('@/assets/images/no-image.jpg')"
                                                        :lazy-src="require('@/assets/images/no-image.jpg')"
                                                        max-height="150"
                                                        max-width="50%"
                                                        :aspect-ratio="1"
                                                        class="white--text align-center justify-center elevation-2">
                                                    </v-img>
                                                </v-row>
                                                <v-row no-gutters align="center" justify="center">
                                                    <v-file-input
                                                        dense
                                                        v-model="imageFile"
                                                        accept="image/png, image/jpeg, image/bmp"
                                                        show-size
                                                        :disabled="loadingSave"
                                                        clearable
                                                        :rules="imageRules"
                                                        label="Cargar/Cambiar imágen"
                                                        placeholder="Archivo de imágen"
                                                        prepend-icon="mdi-camera"
                                                        @change="changePreviewImage()">
                                                    </v-file-input>
                                                </v-row>
                                            </v-col>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-spacer></v-spacer>
                                        <v-btn :disabled="loadingSave"
                                               @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn :disabled="!valid"
                                               color="primary darken-1 caption"
                                               :loading="loadingSave"
                                               @click="save">
                                            <v-icon dark>mdi-content-save</v-icon>
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" width="auto">
                            <v-card>
                                <v-card-title class="text-h6 headline">¿Est&aacute; seguro que desea eliminar esta Categor&iacute;a?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="loadingSave" @click="closeDelete">Cancelar</v-btn>
                                    <v-btn :loading="loadingSave" color="primary darken-1 caption" @click="deleteItemConfirm">Proceder</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.enabled="{ item }">
                    <v-simple-checkbox
                        v-model="item.enabled"
                        disabled
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay Categor&iacute;as en el listado</span>
                </template>
            </v-data-table>
            <v-snackbar
                v-model="snackBar"
                :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs"
                           icon
                           @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
import categoriesService from "@/providers/CategoriesService";
import projectsService from '@/providers/ProjectsService';

export default {
    name: "CategoriesModuleComponent",
    data: () => ({
        snackBar: false,
        snackText: '',
        valid: true,
        searchCat: '',
        loadingData: false,
        loadingSave: false,
        dialog: false,
        dialogDelete: false,
        dialogError: false,
        messageError: '',
        imageFile: null,
        imagePreview: null,
        selectedImageIndex: -1,
        archived: false,
        categories: [],
        projectsList: [],
        classifications: [
            { name: 'gbm', description: 'GBM' },
            { name: 'realtor', description: 'Inmobiliario' },
            { name: 'events', description: 'Eventos y espetáculos' }
        ],
        headers: [
            {text: 'Id', value: 'id', align: ' d-none'}, // para ocultar la columna Id.
            {
                text: 'Nombre',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            {text: "Cantidad de Proyectos", value: "projects_count", align: 'center', filterable: false },
            {text: 'Acciones', value: 'actions', width: 100, align: 'center', sortable: false},
        ],
        editedIndex: -1,
        editedItem: {
            id: 0,
            name: "",
            description: "",
            classification: "",
            projects_count: 0,
            projects: null,
            image_url: ""
        },
        defaultItem: {
            id: 0,
            name: "",
            description: "",
            classification: "",
            projects_count: 0,
            projects: null,
            image_url: ""
        },
        textRules: [
            v => !!v || 'El valor es obligatorio.'
            // v => (v && v.length >= 10) || 'El Nombre debe tener más de un caracter.',
        ],
        imageRules: [
            value => !value || value.size < 2000000 || 'El tamaño de la imagen no debe de sobrepasar los 2 MegaBytes.'
        ]
    }),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nueva Categoría de Proyecto' : 'Editar Categoría de Proyecto';
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    methods: {
        getItemText(item) {
            return `${item.name} ${item.last_name}`;
        },
        getCategories() {
            this.loadingData = true;
            // Lista de Proyectos
            const archived = Number(this.archived);
            projectsService.getViewList(archived).then(record => {
                this.projectsList = record.value;
            });
            categoriesService.getViewList().then(record => {
                this.categories = record.value;
            }).catch(err=>console.log(err)).finally(()=>this.loadingData = false);
        },
        newItem() {
            this.valid = false;
            this.imageFile = null;
            this.imagePreview = null;
            this.selectedImageIndex = -1;
            this.editedItem = Object.assign({}, this.defaultItem);
        },
        editItem(item) {
            this.editedIndex = this.categories.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.imagePreview = null;
            this.selectedImageIndex = -1;
            if (this.editedItem.image_url !== null) {
                this.imagePreview = this.editedItem.image_url;
                this.selectedImageIndex = 0;
            }
            this.imageFile = null;
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.categories.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.loadingSave = true;
            categoriesService.deleteRecord(this.editedItem.id).then(
                response => {
                    if (response.success) {
                        this.categories.splice(this.editedIndex, 1);
                        this.snackText = 'Categoría eliminada con éxito.';
                    } else {
                        this.snackText = 'Un error impidió eliminar la Categoría';
                    }
                }).catch(err => {
                    console.log('Error ejecutando API: ', err);
                    this.messageError = "Un error impidió eliminar la Categoría";
                    this.dialogError = true;
                }).finally(() => {
                    this.closeDelete();
                    this.snackBar = true;
                    this.loadingSave = false;
                });
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.imagePreview = null;
                this.selectedImageIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        save() {
            if (this.imagePreview == null) {
                // console.log('El formulario no contiene un logo asociado');
                this.messageError = 'El formulario no contiene una imagen asociada. Por favor agrege una.';
                this.dialogError = true;
                return;
            }
            this.$refs.form.validate();
            if (this.$refs.form.validate(true)) {
                this.loadingSave = true;
                if (this.editedIndex > -1) {
                    // Actualizar el registro por el metodo PUT
                    Object.assign(this.categories[this.editedIndex], this.editedItem);
                    categoriesService.updateRecord(this.editedItem, this.imageFile).then(
                        response => {
                            if (response.success) {
                                this.getCategories();
                            }
                        }
                    ).catch(err=>console.log(err)).finally(()=>this.loadingSave = false);
                } else {
                    // Agrega el registro por el metodo POST
                    this.categories.push(this.editedItem);
                    categoriesService.addRecord(this.editedItem, this.imageFile).then(
                        response => {
                            if (response.success) {
                                this.getCategories();
                            }
                        }
                    ).catch(err=>console.log(err)).finally(()=>this.loadingSave = false);
                }
                this.close();
                this.snackText = 'Datos de la Categoría guardados con éxito.';
                this.snackBar = true;
            }
        },
        changePreviewImage() {
            if (this.imageFile) {
                // console.log('Data imagen: ', this.imageFile, '\n');
                if (this.imageFile.size > 2000000) {
                    this.imagePreview = null;
                    return;
                }
                let reader = new FileReader();
                reader.onload = () => {
                    this.imagePreview = reader.result;
                    // // console.log('Preview imagen: ',this.imagePreview, '\n');
                };
                reader.readAsDataURL(this.imageFile);
                // console.log('Imagen actualizada: ', this.imageFile.name);
                this.snackText = 'Imágen de la sección cargada con éxito...';
                this.snackBar = true;
            }
        }
    },
    mounted() {
        this.categories = [];
        this.getCategories();
    }
}
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>
