/***********************************************/
// Capa de Servicios para el modulo de Proyectos

import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        }
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async getAllRecords() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/categories", this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getViewList() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/view-list/categories`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async listCategories() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/section-list/categories", this.header);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/categories/${id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async addRecord(formData, imageFile) {
        let result = null;
        let projectsList = formData.projects;
        let recordData = new FormData();
        recordData.append("id", formData.id);
        recordData.append("name", formData.name);
        // recordData.append("job_position", formData.job_position);
        recordData.append("description", formData.description);
        recordData.append("classification", formData.classification);
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.post(this.#baseUrl + "/categories", recordData, this.header);
            console.log('response: ', response);
            if (response.data.success) {
                let data = response.data;
                let record_id = data.id;
                let projectIds = [];
                projectsList.forEach(item => {
                    projectIds.push(item.id);
                });
                this.registerProjects(record_id, projectIds);

                this.uploadImage(record_id, imageFile);
                result = response.data;
            } else {
                result = null;
            }
        } catch (error) {
            result = this.handleError(error);
        }
        return result;
    }

    // Pushes posts to the server when called.
    async updateRecord(formData, imageFile) {
        let result = null;
        let projectsList = formData.projects;
        let recordData = new FormData();
        recordData.append("id", formData.id);
        recordData.append("name", formData.name);
        // recordData.append("job_position", formData.job_position);
        recordData.append("description", formData.description);
        recordData.append("classification", formData.classification);
        recordData.append('_method', 'put');
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.post(`${this.#baseUrl}/categories/${formData.id}`, recordData, this.header);
            if (response.data.success) {
                let data = response.data;
                let record_id = data.id;
                this.uploadImage(record_id, imageFile);
                // Sincroniza las plataformas
                let projectIds = [];
                projectsList.forEach(item => {
                    projectIds.push(item.id);
                });
                this.registerProjects(record_id, projectIds);
                result = response.data;
            } else {
                result = null;
            }
        } catch (error) {
            result = this.handleError(error);
        }
        return result;
    }

    registerProjects(category_id, projects_ids) {
        let formData = new FormData();
        formData.append("category_id", category_id);
        formData.append("projects_ids", projects_ids);
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        axios.post(this.#baseUrl + '/register-projects/categories', formData, this.header)
            .then(response => {
                return response.data;
            })
            /* handle error */
            .catch(error => {
                    return this.handleError(error);
                }
            );
    }

    async deleteRecord(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.delete(`${this.#baseUrl}/categories/${id}`, this.header);
            if (response.data.success) {
                // console.log('Respuesta.data: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    /*deleteRecord(id) {
        let result = null;
        // console.log('Deleting Record: %s ', id);
        axios.delete(`${this.#baseUrl}/categories/${id}`)
            .then(response => {
                // console.log('Respuesta: ', response.data);
                result = response.data;
            })
            /!* handle error *!/
            .catch(error => {
                    // console.log('Error: ', response);
                    result = this.handleError(error);
                }
            );
        return result;
    }*/

    uploadImage(record_id, imageFile) {
        if (imageFile) {
            let formData = new FormData();
            formData.append("record_id", record_id);
            formData.append("file", imageFile);
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            axios.post(this.#baseUrl + '/upload-image/categories', formData, this.header)
                .then(response => {
                    return response.data;
                })
                /* handle error */
                .catch(error => {
                        return this.handleError(error);
                    }
                );
        }
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            valueError = error.response.data.messaje;
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
        }
        console.error('valueError: ', valueError);
        return valueError;
    }

}